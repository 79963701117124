import { Dispatch } from 'redux'
import { Action, ActionType } from '../types/uiType'

export const showSideBar = (show: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SIDEBAR_VISIBILITY,
      payload: show
    })
  }
}

export const updateSpoofedUserEmail = (email: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SPOOFED_USER_EMAIL,
      payload: email
    })
  }
}

export const updateSpoofMode = (show: boolean, userId: string, email: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_SPOOFBANNER_VISIBILITY,
      payload: show
    })
    dispatch({
      type: ActionType.UPDATE_SPOOFED_USER_ID,
      payload: userId
    })
    dispatch({
      type: ActionType.UPDATE_SPOOFED_USER_EMAIL,
      payload: email
    })
  }
}
