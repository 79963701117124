import { Dispatch, Store } from 'redux'
import { RootState } from 'state'
import {
  submitShippingConfirmForAccount,
  submitShippingConfirmForAccountAddons,
  submitShippingConfirmForAccountExisting
} from 'state/apis/shippingApi'
import { Action, ActionType, ShippingType } from 'state/types/shippingType'

export const createShippingConfirmDogs = (formData: ShippingType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SUBMIT_ACCOUNT_SHIPPING_PENDING
    })
    try {
      const success = await submitShippingConfirmForAccount(formData)
      dispatch({
        type: ActionType.ADD_ACCOUNT_ORDER_SUMMARY_SUCCESS,
        payload: { orderSummary: success.orderSummary }
      })
      dispatch({
        type: ActionType.SUBMIT_ACCOUNT_SHIPPING_SUCCESS,
        payload: { shippingConfirm: formData, changed: true }
      })
    } catch (err) {
      dispatch({
        type: ActionType.SUBMIT_ACCOUNT_SHIPPING_FAIL,
        payload: { errorMessage: err }
      })
    }
  }
}

export const createShippingConfirmDogsExisting = (formData: ShippingType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SUBMIT_ACCOUNT_SHIPPING_PENDING
    })
    try {
      const _success = await submitShippingConfirmForAccountExisting(formData)
      dispatch({
        type: ActionType.SUBMIT_ACCOUNT_SHIPPING_SUCCESS,
        payload: { shippingConfirm: formData, changed: true }
      })
    } catch (err) {
      dispatch({
        type: ActionType.SUBMIT_ACCOUNT_SHIPPING_FAIL,
        payload: { errorMessage: err }
      })
    }
  }
}

export const createShippingConfirmDogsExistingAddons = (formData: ShippingType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SUBMIT_ACCOUNT_SHIPPING_PENDING
    })
    try {
      const success = await submitShippingConfirmForAccountAddons(formData)
      dispatch({
        type: ActionType.SUBMIT_ACCOUNT_SHIPPING_SUCCESS,
        payload: { shippingConfirm: formData, changed: true, total: success.total }
      })
    } catch (err) {
      dispatch({
        type: ActionType.SUBMIT_ACCOUNT_SHIPPING_FAIL,
        payload: { errorMessage: err }
      })
    }
  }
}

export const saveShippingConfirmForm = (values: any, fixedOrCustomPlan: string) => {
  return function (dispatch: Dispatch<Action>, getState: Store<RootState>['getState']) {
    const { plan } = getState()
    return dispatch({
      type: ActionType.SAVE_ACCOUNT_SHIPPING_FORM,
      payload: {
        customer: {
          name: values.firstName,
          surname: values.lastName,
          email: values.email,
          marketingOptIn: values.marketingOptIn,
          telephone: values.phoneNumber,
          smsOptIn: values.smsOptIn,
          password: values.password
        },
        address: {
          shipping: {
            address1: values.shippingAddress1,
            address2: values.shippingAddress2,
            city: values.shippingCity,
            county: values.shippingCounty,
            postcode: values.shippingPostcode
          },
          billing: {
            address1: values.billingAddress1,
            address2: values.billingAddress2,
            city: values.billingCity,
            county: values.billingCounty,
            postcode: values.billingPostcode
          }
        },
        coupon: values.coupon,
        notes: values.deliveryInstructions,
        payment: values.payment,
        recipes: plan.recipesSelected,
        addons: plan.addonsSelected,
        deliveryDate: values.deliveryDate,
        plan: {
          id: plan.packSize + 'g-' + plan.weeks + 'w',
          type: fixedOrCustomPlan,
          level: 10,
          size: plan.planLevel,
          grams: (plan.packSize ?? 0).toString() // TODO: type fix required
        }
      }
    })
  }
}

export const resetErrorMessageForFlowShipping = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_ERROR_MESSAGE
    })
  }
}

export const resetChangedShipping = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_SHIPPING_CHANGED
    })
  }
}

export const resetFlowShipping = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET
    })
  }
}
