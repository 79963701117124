import { Cache } from 'aws-amplify'
import { navigate } from 'gatsby'
import Api, { spoofMode, spoofedUserId } from '../api'

export function fetchAccountPlans() {
  const url = spoofMode ? '/v1/private/admin/account/plans?userId=' + spoofedUserId : '/v1/private/account/plans'

  return new Promise<any>((resolve, reject) => {
    const webpackCompilationHash = Cache.getItem('webpackCompilationHash')

    Api.get('wwwREST', url, {
      headers: {
        'Webpack-Compilation-Hash': webpackCompilationHash ? webpackCompilationHash : ''
      }
    })

      .then(res => resolve(res))

      .catch(err => {
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
        console.error('Error fetch account plans')
        reject(err.message)
      })
  })
}

export function fetchAccountOrders() {
  const url = spoofMode
    ? '/v1/private/admin/account/order/all?userId=' + spoofedUserId
    : '/v1/private/account/order/all'

  return new Promise<any>((resolve, reject) => {
    Api.get('wwwREST', url, {})

      .then(res => resolve(res))

      .catch(err => {
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
        console.error('Error fetch account orders')
        reject(err.message)
      })
  })
}

export function updatePlanStatusToPause(payload: any) {
  const url = spoofMode
    ? '/v1/private/admin/account/plan/pause?userId=' + spoofedUserId
    : '/v1/private/account/plan/pause'

  return new Promise<any>((resolve, reject) => {
    Api.post('wwwREST', url, { body: payload })

      .then(res => resolve(res))

      .catch(err => {
        console.log(err.response)
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
        console.error('Error update plan status to pause')
        reject(err.response.data)
      })
  })
}

export function getPlanCancelReasons() {
  const url = spoofMode
    ? '/v1/private/admin/account/plan/cancel?userId=' + spoofedUserId
    : '/v1/private/account/plan/cancel'

  return new Promise<any>((resolve, reject) => {
    Api.get('wwwREST', url, {})

      .then(res => resolve(res))

      .catch(err => {
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
        console.error('Error get plan cancel reasons')
        reject(err.message)
      })
  })
}

export function getPlanPauseReasons() {
  const url = spoofMode
    ? '/v1/private/admin/account/plan/pause?userId=' + spoofedUserId
    : '/v1/private/account/plan/pause'

  return new Promise<any>((resolve, reject) => {
    Api.get('wwwREST', url, {})

      .then(res => resolve(res))

      .catch(err => {
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
        console.error('Error get plan pause reasons')
        reject(err.message)
      })
  })
}

export function updatePlanStatusToCancel(payload: any) {
  const url = spoofMode
    ? '/v1/private/admin/account/plan/cancel?userId=' + spoofedUserId
    : '/v1/private/account/plan/cancel'

  return new Promise<any>((resolve, reject) => {
    Api.post('wwwREST', url, { body: payload })

      .then(res => resolve(res))

      .catch(err => {
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
        console.error('Error update plan status to cancel')
        reject(err.response.data)
      })
  })
}

export function fetchPlanSchedule(subscriptionId?: string) {
  let url = ''
  if (subscriptionId) {
    url = spoofMode
      ? '/v1/private/admin/account/plan/reschedule?subscriptionId=' + subscriptionId + '&userId=' + spoofedUserId
      : '/v1/private/account/plan/reschedule?subscriptionId=' + subscriptionId
  } else {
    url = spoofMode
      ? '/v1/private/admin/account/plan/reschedule?userId=' + spoofedUserId
      : '/v1/private/account/plan/reschedule'
  }

  return new Promise<any>((resolve, reject) => {
    Api.get('wwwREST', url, {})

      .then(res => resolve(res))

      .catch(err => {
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
        console.error('Error fetch plan schedule')
        reject(err.message)
      })
  })
}

export function reschedulePlan(payload: any) {
  const url = spoofMode
    ? '/v1/private/admin/account/plan/reschedule?userId=' + spoofedUserId
    : '/v1/private/account/plan/reschedule'

  return new Promise<any>((resolve, reject) => {
    Api.post('wwwREST', url, { body: payload })

      .then(res => resolve(res))

      .catch(err => {
        if (err.response.status === 503) {
          navigate('/maintenance')
        }
        console.error('Error reschedule plan')
        reject(err.response.data)
      })
  })
}
