import { Dispatch } from 'redux'
import {
  fetchAccountOrders,
  fetchAccountPlans,
  fetchPlanSchedule,
  reschedulePlan,
  updatePlanStatusToCancel,
  updatePlanStatusToPause
} from 'state/apis/account/planApi'
import { Action, ActionType } from '../../types/account/accountPlanType'

export const updatePlans = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PLANS_PENDING
    })

    try {
      const success = await fetchAccountPlans()
      dispatch({
        type: ActionType.UPDATE_PLANS,
        payload: success.plans
      })
    } catch (err) {
      console.log(err)
      dispatch({
        type: ActionType.UPDATE_PLANS_FAIL,
        payload: { errorMessage: err }
      })
    }
  }
}

export const updateOrders = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const success = await fetchAccountOrders()
      dispatch({
        type: ActionType.UPDATE_ORDERS,
        payload: success.orders
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const resetPlansAndOrders = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: ActionType.RESET
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const resetErrorMessageForPlans = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_ERROR_MESSAGE
    })
  }
}

export const pausePlan = (payload: any) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      await updatePlanStatusToPause(payload)
      dispatch({
        type: ActionType.PAUSE_PLAN_SUCCESS
      })
    } catch (err) {
      dispatch({
        type: ActionType.PAUSE_PLAN_FAIL,
        payload: err
      })
    }
  }
}

export const cancelPauseReason = (payload: any) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CANCEL_PAUSE_PLAN_REASON,
      payload: payload
    })
  }
}

export const cancelPlan = (payload: any) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      await updatePlanStatusToCancel(payload)
      dispatch({
        type: ActionType.CANCEL_PLAN_SUCCESS
      })
    } catch (err) {
      console.log(err)
      dispatch({
        type: ActionType.CANCEL_PLAN_FAIL,
        payload: err
      })
    }
  }
}

export const getPlanSchedule = (subscriptionId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const success = await fetchPlanSchedule(subscriptionId)
      dispatch({
        type: ActionType.FETCH_PLAN_SCHEDULE,
        payload: success.shippingDates
      })
    } catch (err) {
      console.log(err)
      dispatch({
        type: ActionType.FAILED_PLAN_SCHEDULE,
        payload: err
      })
    }
  }
}

export const postReschedulePlan = (payload: any) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const success = await reschedulePlan(payload)
      dispatch({
        type: ActionType.FETCH_PLAN_SCHEDULE,
        payload: success.shippingDates
      })

      dispatch({
        type: ActionType.POST_RESCHEDULE_PLAN_SUCCESS
      })
    } catch (err) {
      console.log(err)
      dispatch({
        type: ActionType.POST_RESCHEDULE_PLAN_FAIL,
        payload: err
      })
    }
  }
}
